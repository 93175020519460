.listings {
  display: grid;
  grid-gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1020px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1330px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.search-input {
  margin: 0 0 10px 0;
}
